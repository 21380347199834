<template>
  <div>
    <Header 
      button="Adicionar Download"
      title="Gerenciamento de Downloads"
      subtitle="Gerencie o conteúdo da página Downloads"
      @click="getDownload(null)"
    />
    <div v-if="downloads.length" class="max-w-7xl mx-auto mt-3 px-4 sm:px-6 lg:px-8">
      <div class="bg-white shadow overflow-hidden rounded-md">
        <ul class="divide-y divide-gray-200">
          <li v-for="(item, ind) in downloads" :key="ind">
            <div class="flex items-center justify-between px-4 py-4 sm:px-6 hover:bg-gray-50">
              <div class="min-w-0 flex-1">
                <p class="font-medium text-sm text-primary truncate"># {{ item.title }}</p>
                <div class="hidden sm:flex sm:items-center text-xs font-medium text-gray-600 space-x-1.5">
                  <svg v-if="item.link" xmlns="http://www.w3.org/2000/svg" class="-mr-0.5 h-3 w-3" viewBox="0 0 20 20" fill="currentColor"><path d="M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z" /><path d="M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z" /></svg>
                  <span v-if="item.link">Link</span>
                  <svg v-if="item.path" xmlns="http://www.w3.org/2000/svg" class="-mr-0.5 h-3 w-3" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M4.5 2A1.5 1.5 0 003 3.5v13A1.5 1.5 0 004.5 18h11a1.5 1.5 0 001.5-1.5V7.621a1.5 1.5 0 00-.44-1.06l-4.12-4.122A1.5 1.5 0 0011.378 2H4.5zm4.75 6.75a.75.75 0 011.5 0v2.546l.943-1.048a.75.75 0 011.114 1.004l-2.25 2.5a.75.75 0 01-1.114 0l-2.25-2.5a.75.75 0 111.114-1.004l.943 1.048V8.75z" clip-rule="evenodd" /></svg>
                  <span v-if="item.path">Arquivo</span>
                </div> 
              </div>
              <button @click="getDownload(item, ind)" type="button" class="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /></svg>
              </button>
              <button @click="$modal.show('confirm'), index = ind" type="button" class="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <t-modal name="download" class="z-45">
      <p class="text-base font-medium text-gray-800">Novo Download</p>
      <form @submit.prevent class="mt-4">
        <div class="mb-2">
          <label for="name" class="block text-sm font-medium text-gray-700">Título</label>
          <input v-model="title" type="text" name="street" id="street" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
        </div>
        <div class="w-full">
          <label for="identify" class="block text-sm font-medium text-gray-700">Conteúdo (Link externo ou upload de arquivo)</label>
          <div class="mt-1 flex rounded-md shadow-sm">
            <input v-model="link" type="text" name="identify" id="link" class="focus:ring-primary focus:border-primary block w-full rounded-none rounded-l-md sm:text-sm border-gray-300" placeholder="Link">
            <label for="file-upload" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              <svg v-if="file" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" /></svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" /></svg>
              <span class="whitespace-nowrap">{{ file ? `${file.substring(0, 4)}...` : ( path ? 'Arquivo selecionado' : 'Arquivo') }}</span>
              <input id="file-upload" name="file-upload" type="file" class="sr-only" @change="uploadAttachment">
            </label>
          </div>
        </div>
        <button @click="saveDownload()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Salvar
        </button>
      </form>
    </t-modal>
    <t-modal name="confirm" class="z-45">
      <p class="text-base font-medium text-gray-800">Apagar Download</p>
      <form @submit.prevent class="mt-8 text-center">
        <p class="mb-2 font-medium">Tem certeza que deseja excluir esse download?</p>
        <button @click="deleteDownload()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Apagar
        </button>
      </form>
    </t-modal> 
  </div>
</template>

<script>
import { firebase, listingsColl, storageRef } from '@/firebase'

export default {
  components: { Header: () => import('@/components/layout/header') },

  data() { return { 
    downloads: [], 
    file: '',
    path: '',
    link: '',
    title: '', 
    action: '',
    index: null,
    uploadTask: '',
  } },

  mounted() { this.getDownloads() },
  
  methods: {
    async getDownloads() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('Downloads').get()
      .then((result) => { this.downloads = result.data().available })
      .catch(() => { this.$toast.error('Falha ao obter os downloads, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    getDownload(item, index) {
      if (!item) {
        this.action = 'Novo', this.index = null, this.file = '', this.path = '', this.link = '', this.title = ''
        this.$modal.show('download')
      } else {
        this.action = 'Editar', this.index = index, this.file = item.file || '', this.path = item.path || '', this.link = item.link || '', this.title = item.title || ''
        this.$modal.show('download')
      }
    },

    async uploadAttachment(e) {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      const temp = this.path || ''
      this.file = e.target.files[0].name
      this.uploadTask = await storageRef.child(`downloadsAttachments/${Date.now()}.${(e.target?.files[0]?.name || 'a.txt').split('.')[1]}`).put(e.target.files[0])
      .then(async response => { 
        await response.ref.getDownloadURL().then(downloadURL => { this.path = downloadURL }) 
        if (temp) {
          const reference = temp.substring(temp.indexOf('downloadsAttachments'), temp.indexOf('?alt=')).replace('%2F', '/')
          await storageRef.child(reference).delete().catch(() => {})
        }
      })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async saveDownload() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      if (this.title.length === 0) {
        this.$store.commit('setLoading', false)
        return this.$toast.error('Preencha o nome do download')
      }
      if (this.title.includes('/')) {
        this.$store.commit('setLoading', false)
        return this.$toast.error('Caracter inválido, tente novamente')
      }
      if (this.index === null && this.downloads.find(i => i.title === this.title)) {
        this.$store.commit('setLoading', false)
        return this.$toast.error('Título já existente')
      }
      if (!this.link && !this.path) {
        this.$store.commit('setLoading', false)
        return this.$toast.error('Insira um link ou um arquivo como conteúdo')
      }
      if (this.index !== null) {
        this.downloads[this.index].link = this.link
        this.downloads[this.index].path = this.path
        this.downloads[this.index].title = this.title
        await listingsColl.doc('Downloads').update({ available: this.downloads })
        .then(() => { 
          this.index = null, this.title = '', this.file = '', this.path = '', this.link = ''
          this.$modal.hide('download')
          this.$toast.success('Download adicionado com sucesso'), this.getDownloads() 
        })
        .catch(() => { this.$toast.error('Falha ao salvar download, tente novamente') })
      } else {
        await listingsColl.doc('Downloads').update({ available: firebase.firestore.FieldValue.arrayUnion({ title: this.title, link: this.link || '', path: this.path || '' }) })
        .then(() => { 
          this.index = null, this.title = '', this.file = '', this.path = '', this.link = ''
          this.$modal.hide('download')
          this.$toast.success('Download adicionado com sucesso'), this.getDownloads() 
        })
        .catch(() => { this.$toast.error('Falha ao salvar download, tente novamente') })
      }
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async deleteDownload() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      const path = this.downloads[this.index].path || ''
      this.downloads.splice(this.index, 1)
      await listingsColl.doc('Downloads').update({ available: this.downloads })
      .then(async () => { 
        if (path) {
          const reference = path.substring(path.indexOf('downloadsAttachments'), path.indexOf('?alt=')).replace('%2F', '/')
          await storageRef.child(reference).delete().catch(() => {})
        }
        this.$toast.success('Download excluído com sucesso')
        this.getDownloads() 
      })
      .catch(() => { this.$toast.error('Falha ao excluir download, tente novamente') })
      this.$modal.hide('confirm')
      this.index = null
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    }
  }  
}
</script>